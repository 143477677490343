
.menu {
    display    : flex;
    align-items: center;
    flex-flow  : column;
    width      : 100% !important;
    margin-top: 2rem !important;
}

.drawer{
    // background-color: @primary-color;
    flex: unset !important;
    max-width: unset !important;
    .ant-drawer-content{
        background-color: transparent !important;
    }
}
.sider{
    flex: unset !important;
    max-width: unset !important;
    width: 100% !important;
    max-width: 400px !important;
}

.namespace{
    color: white;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid #c4c4c457;
}
.menuItem{
    margin: 0.5rem !important;
    font-size: 18px;
    padding-left: 1.5rem !important;
    a{
        display: flex;
        align-items: center;
    }
    svg{
        margin-right: 1rem !important;
    }
    path{
        fill: white;
    }
}

.selectedMenu{
    background-color: @primary-color;
    &:hover{
        background-color: @primary-color !important;
    }
}
.marginRight{
    margin-right: 1rem !important;
}
.flex{
    display: flex !important;
    align-items: center;
}
.needHelpDiv {
    position: absolute;
    bottom: 21px;
    color: #fff;
    margin-left: 2rem;
}
@primary-color: #1890ff;@layout-header-background: #001529;