@media only screen and (max-width: 800px) {
  .header .heading {
    display: inline-block !important;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px !important;
  }
  .header .overflowAddress {
    display: inline-block !important;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 321px) {
  #headShopImage {
    display: none !important;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  padding: 0 1rem;
  height: 73px;
}
.username {
  font-size: 15px;
  border-bottom: none !important;
}
.heading {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: -0.5rem;
}
.headTools {
  display: flex;
  align-items: center;
}
.label {
  font-weight: 500;
}
.headText path {
  fill: #C4C4C4 !important;
}
