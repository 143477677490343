@import '../../mediaVar.less';

@media @desktop {
  .headText {
    // .heading{
    //   font-size: 18px !important;
    // }
  }
}

@media @mobile {
  .header {
    .heading {
      display: inline-block !important;
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px !important;
    }
    .overflowAddress {
      display: inline-block !important;
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

}
@media @smallmobile {
  #headShopImage {
    display: none !important;
  }
}