
.orderTitle {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 259px;
    flex: 1;
    height: 5rem;
    margin: 0;
    font-size: 34px;
    line-height: 76px;
    font-weight: 500;
    
}
.ordersMenu {
    border-radius: 10px !important;
    .ant-card-body{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
.categoryListItem {
    color: #C4C4C4 !important;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    .ant-list-item-meta-avatar{
        path{
            fill: #C4C4C4 !important;         
            stroke: #C4C4C4 !important;         
        }
    }
    .ant-list-item-meta-title{
        color: #C4C4C4 !important;        
    }
}
.categoryListItemSelected {
    color: black !important;
    .ant-list-item-meta-avatar{
        path{
            fill: initial !important;            
            stroke: initial !important;            
        }
    }
    .ant-list-item-meta-title{
        color: black !important;
    }
}
.ordersListWrapper{
    width: calc(100% - 300px);
    margin-right: 1rem ;
    margin-left: 1rem ;
    border-radius: 10px !important;
    background-color: white;
    overflow: auto;
}
.orderListTitle {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 259px;
    flex: 1;
    height: 5rem;
    margin: 0;
    font-size: 34px;
    line-height: 76px;
    font-weight: 500;
}
.orderListCard {
    border-radius: 10px !important;
    max-height: calc(100vh - 73px - 7.05rem);
    overflow-y: auto;
}
.orderList{
    display:  flex;
    
}
.orderListDetails {
    flex: 1;
    margin-left: 30px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: space-around;
}
.orderListUnit {
    font-size: 16px;
    color: #BDBDBD;
    line-height: 24px;
}
.orderListPrice {
    font-size: 20px;
    font-weight: 600;
    width: fit-content;
}
.orderListAdd {
    display: flex;
    min-width: 120px ;
    flex-direction: column;
    justify-content: flex-start;
    button{
        margin-top: 10px;
    }
}

// --------------------Order Details Styles -------------------------------

.cancelCountdown {

    .secondaryText, .ant-statistic-content-value{
            color: #fff !important;
    } 
}
.orderDetailWrapper{
    // width: calc(100% - 164px);
    margin: 0 8rem;
}
.orderDetailCard {
    border-radius: 10px;
    max-height: calc(100vh - 73px - 7.05rem);
    overflow-y: auto;
}
.order__shopDetails {
    display: flex;
}
.successTag {
    font-size: 14px !important;
    min-width: 120px;
    text-align: center;
    padding: 0.2rem !important;
}
.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 165px;
}
.steps {
    margin-top: 4rem;
    margin-bottom: 2rem;
       
}
.order__dividerTitle {
    font-size: 2rem !important;
    font-weight: 500 !important;
    padding: 10px;
}
.order__detailsMain {
    display: flex;
    justify-content: space-between;
}
.order__detailsTotal {
    width: 50%;
    margin-right: 1rem;
    .ant-descriptions-row > th, .ant-descriptions-row > td {
        padding: 0 !important;
    }
}
.order__detailsCustomer {
    width: 50%;
    margin-left: 1rem;
}
.order__customerDetails {
    padding: 0 !important;
    font-size: 22px;
    font-weight: 500;
    .ant-page-header-heading-title {
        font-size: 22px;
        font-weight: 600;
        margin-left: 10px;
        }
}
.order__bottomDescriptions {
    margin-left: 12px;
    .ant-descriptions-view, .ant-descriptions-row{
        border: none !important;
    }
}
.addressWrap{
    // margin-top: 5rem;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
}
.orderListItemDiv{
    color: #C4C4C4;
    font-size: 12px;
    div, span{
        white-space: nowrap;
        overflow-x: hidden;
        width: 90%;
        text-overflow: ellipsis;
    }
}
.orders__listFilters {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 5rem;
    right: 1rem;
}
.orders__listSearch {
        background: white !important;
        border-radius: 4px !important;
        width: 200px !important;
}
.appliedCoupon1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: aliceblue;
    // padding: 0.1rem 0.5rem;
    // font-weight: 600;
    font-size: 14px;
    color: @primary-color;
    svg{
        color: red;
    }
}

.itemParent {
    border: 2px solid #00b6ff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9de1fe;
    border-radius: 2px;
    margin-right: 10px;
    
}

#settingsWrapper{
    display: flex;
    justify-content: space-between;
}
.orderDetailsTitleDiv{
    justify-content: space-between !important;
    width: 100%;
    margin-right: 8rem
}
.orderDetailsShop{
    display: flex;
    justify-content: space-between;
    flex: 1;
}
.description{
    justify-content: space-between;
    max-width: 350px;
    span{
        min-width: 40%;
        text-align: right;
    }
    span:first-child{
        flex: 1;
        text-align: left;
    }
}
@primary-color: #1890ff;@layout-header-background: #001529;