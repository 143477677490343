@import '../mediaVar.less';

.cartLayoutWrapper {
    display: flex;

    .ant-tabs-nav-wrap {
        width: 100% !important;

        div {
            font-size: 22px;
            font-weight: 500;
            text-align: center;
            flex: 1;
        }
    }

    .ant-tabs-top>.ant-tabs-nav::before,
    .ant-tabs-bottom>.ant-tabs-nav::before,
    .ant-tabs-top>div>.ant-tabs-nav::before,
    .ant-tabs-bottom>div>.ant-tabs-nav::before {
        border-bottom: 4px solid #DDDDDD !important;
    }

    // border: 1px solid #000;
}


.cartView {
    flex: 1;
}


.cartModal {
    width: unset !important;

    .ant-modal-body {
        padding: 0 !important;
    }
}


.headTitleLayout {
    height: 5rem;
    display: flex;
    align-items: center;
    width: 100%;
}

.pageHeaderTitle {
    font-size: 34px;
    font-weight: 500;
    height: 100%;
    line-height: 51px;
    width: 100%;

    .backIcon {
        svg {
            font-size: 30px;
        }
    }

    justify-content: flex-start !important;
}

@media @desktop {

    .pageHeaderTitle {
        font-size: 24px !important;
    }
}
@media @mobile {

    .headTitleLayout {
        height: 2rem !important;
        margin-bottom: 1rem !important;
    }

}
@primary-color: #1890ff;@layout-header-background: #001529;