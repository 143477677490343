.flex {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.rounded {
  border-radius: 4px !important;
}
.cursorPointer {
  cursor: pointer;
}
.primaryTextColor {
  color: #1890ff !important;
}
.cutMrp {
  text-decoration: line-through;
  color: #7d7c7c;
}
.red {
  color: red;
}
.green {
  color: green;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.marginTop {
  margin-top: 1rem !important;
}
.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
@media only screen and (max-width: 1100px) {
  .productListWrapper {
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 570px) and (max-width: 1000px) {
  /* STYLES HERE */
  .steps .ant-steps-item-tail {
    margin: 0 0 0 45px !important;
  }
  .steps .ant-steps-item-icon {
    margin-left: 43px !important;
  }
  .steps .ant-steps-item-content {
    width: 100px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .orderListItemDiv {
    width: 200px !important;
  }
  .textOverflow {
    width: 120px !important;
  }
  .refundDiv {
    font-size: 9px;
  }
}
@media only screen and (max-width: 800px) {
  #productWrapper {
    display: flex !important;
    flex-direction: column !important;
  }
  #productWrapper .productListWrapper {
    width: 100% !important;
  }
  #settingsWrapper {
    display: flex !important;
    flex-direction: column !important;
    max-height: calc(100vh - 73px - 5rem);
  }
  .orderTitle {
    display: none !important;
  }
  .orderListItemDiv {
    width: unset !important;
  }
  .orderListItemDiv div {
    width: 100% !important;
  }
  .ordersListWrapper {
    height: 100% !important;
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .orders__listFilters {
    position: static !important;
    flex-wrap: wrap;
    height: unset !important;
    padding: 1rem;
  }
  .orderListDetails {
    padding-left: 0 !important;
    margin-left: 0 !important;
    padding-right: 20px !important;
  }
  .orderListAdd {
    margin-top: 0.5rem;
    font-size: 11px !important;
    flex-direction: column !important;
    width: unset !important;
    min-width: unset !important;
  }
  .orderListAdd button {
    margin-top: 0px !important;
  }
  .orderList {
    justify-content: space-between !important;
  }
  .orderList .imgThumb,
  .orderList #imgThumb {
    display: none !important;
  }
  .orderList .orderListDetails {
    width: 50% !important;
  }
  .report__formItems {
    width: 100% !important;
    margin-bottom: 0 !important;
  }
  .report__formItems .ant-input {
    padding-left: 0px !important;
  }
  .report__formItemsOther .ant-input {
    padding-left: 0px !important;
  }
  .address__addForm {
    width: 100% !important;
  }
  .address__addForm .ant-form-item {
    width: 100% !important;
    padding: 0 !important;
    margin: 1rem 0 !important;
  }
  .checkoutCard {
    margin-right: 0 !important;
  }
  .checkoutCard .ant-steps-small .ant-steps-item-icon {
    width: 18px !important;
    height: 18px !important;
    line-height: 18px !important;
    font-size: 10px !important;
  }
  .checkoutCard .ant-steps-small .ant-steps-item-title {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .checkoutCard .ant-steps-small .ant-steps-item-title::after {
    top: 8px !important;
  }
  .orderDetailsTitleDiv {
    margin: 0 !important;
  }
  .orderDetailWrapper {
    margin: 0 !important;
  }
  .orderDetailWrapper #bigImgThumb {
    width: 65px !important;
  }
  .orderDetailWrapper .bigImgThumb {
    width: inherit !important;
  }
  .orderDetailWrapper #imgThumb {
    width: 65px !important;
  }
  .orderDetailWrapper .order__shopDetails {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
  .orderDetailsShop {
    flex-direction: column;
    margin-left: 1rem;
  }
  .orderDetailsShop .orderListDetails {
    font-size: 16px;
  }
  .orderDetailsShop .orderListUnit {
    font-size: 14px;
  }
  .order__detailsMain {
    flex-direction: column;
  }
  .order__detailsMain .order__detailsTotal {
    width: 100% !important;
  }
  .order__detailsMain .order__detailsCustomer {
    width: 100% !important;
    margin-left: 0;
  }
  #orderItemDate {
    font-size: 12px !important;
  }
  #orderItemName {
    font-size: 14px !important;
  }
}
.content {
  width: 100% !important;
  height: calc(100vh - 73px) !important;
  padding: 1rem !important;
}
#root > section > section > main > div > div.cartLayoutRightView > div {
  border-radius: 10px;
}
.searchProduct {
  background: white !important;
  border-radius: 10px !important;
  width: 200px !important;
  height: fit-content;
  width: 100% !important;
}
.searchProduct button {
  display: none;
}
.ant-input-prefix {
  color: '#C4C4C4';
}
.searchInputMenu {
  display: flex;
  flex-direction: column;
  width: 259px;
}
.productsMenu {
  margin-top: 32px !important;
  border-radius: 10px !important;
}
.productsMenu .ant-card-body {
  max-height: calc(100vh - 73px - 7rem - 60px);
  overflow-y: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.categoryListItem {
  color: #C4C4C4 !important;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.categoryListItem .ant-list-item-meta-avatar path {
  fill: #C4C4C4 !important;
  stroke: #C4C4C4 !important;
}
.categoryListItem .ant-list-item-meta-title {
  color: #C4C4C4 !important;
}
.categoryListItemSelected {
  color: black !important;
}
.categoryListItemSelected .ant-list-item-meta-avatar path {
  fill: initial !important;
  stroke: initial !important;
}
.categoryListItemSelected .ant-list-item-meta-title {
  color: black !important;
}
.heading {
  display: flex;
  justify-content: space-between;
}
.cartButtons {
  width: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-color: #1890ff !important;
}
.cartItemCount {
  max-width: 70px !important;
  min-width: 30px !important;
  text-align: center !important;
  border: 1px solid #1890ff !important;
  margin: 0 -1px !important;
  line-height: 10px !important;
  flex: 1 !important;
}
.cartItemCount .ant-input-number-handler-wrap {
  display: none !important;
}
.cartItemCount .ant-input-number-input {
  text-align: center !important;
  font-weight: 600;
}
#quantityHandler .ant-input,
.qtybtnGrp .ant-input,
#quantityHandler .ant-input-group-addon:first-child,
.qtybtnGrp .ant-input-group-addon:first-child,
#quantityHandler .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.qtybtnGrp .ant-select:not(.ant-select-customize-input) .ant-select-selector,
#quantityHandler .ant-input-affix-wrapper,
.qtybtnGrp .ant-input-affix-wrapper,
#quantityHandler .ant-input-number,
.qtybtnGrp .ant-input-number {
  border-left: 1px solid #1890ff !important;
  border-right: 1px solid #1890ff !important;
  border-top: 1px solid #1890ff !important;
  border-bottom: 1px solid #1890ff !important;
}
.ant-input-number.cartItemCount {
  width: 100% !important;
  height: fit-content;
}
.cartItemButtonSpan {
  border: 1px solid #1890ff;
  border-radius: 5px;
}
#imgThumb {
  width: 87px;
  height: 67px;
  display: flex;
  justify-content: center;
}
.imgThumb {
  width: unset !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
}
#bigImgThumb {
  width: 126px;
  height: 93px;
  display: flex;
  justify-content: center;
}
.bigImgThumb {
  width: unset !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
}
@media only screen and (max-width: 800px) {
  .layout {
    display: flex !important;
    flex-direction: column !important;
  }
}
