@import '../mediaVar.less';

@media @desktop {
    .productListWrapper {
        margin-right: 0 !important;
    }
}

@media screen and (min-width: 570px) and (max-width: 1000px) {

    /* STYLES HERE */
    .steps {
        .ant-steps-item-tail {
            margin: 0 0 0 45px !important;
        }

        .ant-steps-item-icon {
            margin-left: 43px !important;
        }

        .ant-steps-item-content {
            width: 100px !important;
        }
    }
}

@media @tab {
    .orderListItemDiv {
        width: 200px !important;
    }

    .textOverflow {
        width: 120px !important;
    }
    .refundDiv{
        font-size: 9px;
    }

}

@media @mobile {

    #productWrapper {
        display: flex !important;
        flex-direction: column !important;

        .productListWrapper {
            width: 100% !important;
        }
       
    }

    #settingsWrapper {
        display: flex !important;
        flex-direction: column !important;
        max-height: calc(100vh - 73px - 5rem);
    }

    .orderTitle {
        display: none !important;
    }

    .orderListItemDiv {
        width: unset !important;

        div {
            width: 100% !important;
        }
    }

    .ordersListWrapper {
        height: 100% !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .orders__listFilters {
        position: static !important;
        flex-wrap: wrap;
        height: unset !important;
        padding: 1rem;
    }

    .orderListDetails {
        padding-left: 0 !important;
        margin-left: 0 !important;
        padding-right: 20px !important;
    }

    .orderListAdd {
        margin-top: 0.5rem;
        font-size: 11px !important;
        flex-direction: column !important;
        width: unset !important;
        min-width: unset !important;

        button {
            margin-top: 0px !important;
        }
    }

    .orderList {
        justify-content: space-between !important;

        .imgThumb,
        #imgThumb {
            display: none !important;
        }

        .orderListDetails {
            width: 50% !important;
        }
    }

    .report__formItems {
        width: 100% !important;
        margin-bottom: 0 !important;

        .ant-input {
            padding-left: 0px !important;
        }
    }

    .report__formItemsOther {
        .ant-input {
            padding-left: 0px !important;
        }
    }

    .address__addForm {
        width: 100% !important;
    }

    .address__addForm .ant-form-item {
        width: 100% !important;
        padding: 0 !important;
        margin: 1rem 0 !important
    }

    .checkoutCard {
        margin-right: 0 !important;

        .ant-steps-small .ant-steps-item-icon {
            width: 18px !important;
            height: 18px !important;
            line-height: 18px !important;
            font-size: 10px !important;
        }

        .ant-steps-small .ant-steps-item-title {
            font-size: 12px !important;
            line-height: 18px !important;
        }

        .ant-steps-small .ant-steps-item-title::after {
            top: 8px !important;
        }
    }

    // order details media queries

    .orderDetailsTitleDiv {
        margin: 0 !important;
    }

    .orderDetailWrapper {
        margin: 0 !important;

        #bigImgThumb {
            width: 65px !important;
            // height: 100% !important;
        }

        .bigImgThumb {
            width: inherit !important;
        }

        #imgThumb {
            width: 65px !important;
            // height: 100% !important
        }

        .order__shopDetails {
            margin-left: -12px !important;
            margin-right: -12px !important;
        }
    }

    .orderDetailsShop {
        flex-direction: column;
        margin-left: 1rem;

        .orderListDetails {
            font-size: 16px;
        }

        .orderListUnit {
            font-size: 14px;
        }
    }

    .order__detailsMain {
        flex-direction: column;

        .order__detailsTotal {
            width: 100% !important;
        }

        .order__detailsCustomer {
            width: 100% !important;
            margin-left: 0;
        }
    }

    #orderItemDate {
        font-size: 12px !important;
    }

    #orderItemName {
        font-size: 14px !important;
    }
}