.flex {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.rounded {
    border-radius: 4px !important;
}

.cursorPointer {
    cursor: pointer;
}

.primaryTextColor {
    color: @primary-color  !important;
}

.cutMrp {
    text-decoration: line-through;
    color: #7d7c7c;
}

.red {
    color: red;
}

.green {
    color: green;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.marginTop {
    margin-top: 1rem !important;
}

.textOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}